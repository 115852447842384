import behnamSuperman from "../data/behnam_superman_cropped.jpg";
let userInput, terminalOutput;

var jsFileLocation = $('script[src*=index]').attr('src');
jsFileLocation = jsFileLocation.replace('example.js', '');
console.log(`jsFileLocation is ${jsFileLocation}`)
const app = () => {
  console.log("app is called.....")
  window.userInput = document.getElementById("userInput");
  terminalOutput = document.getElementById("terminalOutput");
  console.log(`terminal output is ${terminalOutput}`)
  document.getElementById("dummyKeyboard").focus();
};

const execute = function executeCommand(input) {
  let output;
  input = input.toLowerCase();
  console.log(`input is ${input}`)
  if (input.length === 0) {
    return;
  }

  // "Secret" party command
  if (input === "party") {
    startTheParty();
  }

  // Another "Secret" command
  if (input === "sudo rm -rf") {
    whooops();
  }

  output = `<div class="terminal-line"><span class="success">➜</span> <span class="directory">~</span> ${input}</div>`;
  if (!COMMANDS.hasOwnProperty(input)) {
    output += `<div class="terminal-line">no such command: <span class="output">"${input}"</span></div>`;
  } else {
    output += `<div class="output"> ${COMMANDS[input]} </div>`;
  }
  console.log(`terminal -> ${terminalOutput}`)
  terminalOutput.innerHTML = `${
    terminalOutput.innerHTML
  }<div class="terminal-line">${output}</div>`;
  terminalOutput.scrollTop = terminalOutput.scrollHeight;
};

const key = function keyEvent(e) {
  userInput = document.getElementById("userInput");
  console.log(`user input is ${userInput}`)
  const input = window.userInput.innerHTML;

  if (e.key === "Enter") {
    console.log("enter was pressed.")
    execute(input);
    userInput.innerHTML = "";
    return;
  }

  userInput.innerHTML = input + e.key;
};

const backspace = function backSpaceKeyEvent(e) {
  if (e.keyCode !== 8 && e.keyCode !== 46) {
    return;
  }
  userInput.innerHTML = userInput.innerHTML.slice(
    0,
    userInput.innerHTML.length - 1
  );
};

document.addEventListener("keydown", backspace);
document.addEventListener("keypress", key);
console.log(`doc ready state is ${document.readyState}`)
if (document.readyState !== "loading") {
  console.log("calling appppp")
  app();
}

// TOP SECRET, DON'T READ
const startTheParty = () => {
  var confettiSettings = {
    target: "canvas",
    max: "1000",
    size: "1",
    animate: true,
    props: ["square", "triangle", "line"],
    colors: [[165, 104, 246], [230, 61, 135], [0, 199, 228], [253, 214, 126]],
    clock: "25",
    rotate: true,
    width: "1680",
    height: "971",
    start_from_edge: true,
    respawn: false
  };
  var confetti = new ConfettiGenerator(confettiSettings);
  confetti.render();
};

const whooops = () => {
  document.body.querySelector(".hero").remove();
  document.body.style.background = "#000";
  document.body.style.width = "100vw";
  document.body.style.height = "100vh";
};


const COMMANDS = {
  help:
    'Supported commands: ["<span class="code">about</span>", "<span class="code">experience</span>", "<span class="code">education</span>", "<span class="code">skills</span>", "<span class="code">contact</span>", "<span class="code">party</span>", "<span class="code">credit</span>"]',
  about:
    // `<div style="displan: inline-flex; align-items: center;">hi<img src=${jsFileLocation + "/../data/behnam_superman_cropped.jpg"} width="180px" /></div>`,
    `<div style="display: inline-flex; align-items: center; flex-direction: column;">
      <img style="border-radius: 30px;" src=${behnamSuperman} class="center" /> 
      <br>
      <span style='white-space:normal;'>
      My name is <span style='font-size: 1.2rem; white-space:nowrap'>Behnam,</span> which means best name in Persian! I'm passionate to utilize my ML, NLP, SWE knowledge to build innovative AI products. 
      I'm currently a Staff Machine Learning and Software Engineer at Motorola Solutions which acquired our startup Openpath Security Inc in 2021 which is now re-branded as Avigilon. 
      During my 4+ years at Openpath, I've been designing, delivering, and maintaining various software/ML services including the first/latest AI product of the company - the conversational agent and voice assistant of <a class="a-terminal" href="https://www.avigilon.com/access-control/video-intercom-reader-pro">Opanpath's Video Intercoms</a>. 
      Prior to joining Openpath, I got my PhD in Computer Science (ML/NLP domains) and MSc (AI in health topic) from UCLA and had the opportunity to intern at Microsoft Research, Adobe, Paypal, and Factual as a Data Scientist Researcher.
      <br/>
      My main hobbies are playing sports (soccer, volleyball, tennis, ping pong, etc), playing instruments (e.g. Persian Setar), or making AI arts. For example, I use stable diffusion models to create artistic QR codes or use midjourney to create images like the one above, revealing my true identity! :D
      </span>

    </div>`,
  skills:
    `<span class="code">Languages:</span> Python, C++, SQL, HTML, CSS, JavaScript, Bash Scripting<br>
     <span class="code">Machine Learning:</span> Classical ML, Deep Learning, NLP, LLM, Conversational AI, Chatbot and Voice AI <br>
     <span class="code">Software Technologies:</span> Docker, Git, Jira, Confluence, Pytest, Vim, VScode, REST API\'s, MQTT, Websockets, Webrtc <br>
     <span class="code">Cloud Computing Platforms:</span> AWS lambda, ec2, ecs, ALB, s3, kinesis, sns, elastiCache, CW, etc (familiar with Google and Azure)`,
  education:
    `<span class="code">PhD in Computer Science (NLP domain):</span> University of California, Los Angeles (UCLA)<br>
    <span class="code">MSc. in Computer Science (AI in healthcare):</span> UCLA <br>
    <span class="code">BSc. in Computer Engineering (Theoretical Algorithms and Networks):</span> Sharif University of Technology <br>`,
  experience:
    `<span class="code">Motorola Solutions:</span> Staff Machine Learning and Software Engineer - developed a voice ai solution for intercoms from idea to product <br>
    <span class="code">Openpath Security (Acquired by Motorola Solutions):</span> Senior Data Scientist - led ML/SWE work for Openpath's video redears and intercoms <br>
    <span class="code">Microsoft Research Lab:</span> Research intern at Conversation Search team - improved Bing's requery suggestions<br>
    <span class="code">Factual:</span> Data Science Intern - built next place prediction models <br>
    <span class="code">Adobe Systems:</span> Data Science Intern - developed interpretable churn prediction models <br>
    <span class="code">Paypal:</span> Data Science Intern - researched on identifying a data shift in PayPal transactions helping with fraud detection<br>`,
  contact:
    'You can contact me on any of the following links:<br>["<a target="_blank" rel="noopener noreferrer" href="https://github.com/behnam354" class="social link">GitHub</a>", "<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/behnam-shahbazi/" class="social link">Linkedin</a>"]',
  party: "🎉🎉🎉",
  credit:
    `This terminal appearance is inspired by its original author "<a target="_blank" rel="noopener noreferrer" href="https://www.twanmulder.com/" class="social link">Twan Mulder</a>"`,
  "sudo rm -rf": ""
};
